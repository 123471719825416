<template>
  <SidePannel
    uid="copying-details-pannel"
    :show="isCopyingDetails"
    :reset-listener="resetListener"
    @close="closeCopyDetails"
  >
    <Spinner
      complete-message="questions successfully saved"
      message="saving questions"
      :is-loading="isLoading"
    />
    <h2><VisibleText>Select Questions</VisibleText></h2>
    <p>
      <VisibleText>Choose questions you want to copy details to.</VisibleText>
    </p>

    <div
      class="dataset-benchmarks-selector__user-q"
      v-if="Object.keys(clientQuestion).length > 0"
    >
      <span class="dataset-benchmarks-selector__subtitle">
        <VisibleText>Your Question</VisibleText>
      </span>
      <p>
        {{ clientQuestion.question_title }} -
        {{ clientQuestion.question_text }} <br />
        <span class="dataset-question-select__values-txt">
          <strong> <VisibleText>Values</VisibleText>: </strong>
          {{ getValuesText(clientQuestion) }}
        </span>
      </p>
    </div>
    <div class="dataset-benchmarks-selector__search forms__search">
      <label class="forms__search-label" for="question-search">
        <span class="visually-hidden">
          <VisibleText>Search global questions</VisibleText>
        </span>
        <SvgIconDecorative icon="search" />
      </label>
      <input
        type="text"
        class="forms__search-input"
        id="question-search"
        placeholder="Search questions"
        v-model="questionSearch"
      />
    </div>

    <form class="dataset-benchmarks-selector__form" @submit.prevent>
      <fieldset
        class="form-fieldset-group dataset-benchmarks-selector__fieldset light-scroll-bar"
        id="copy-details-questions-group"
      >
        <legend class="sr-only">
          <VisibleText>select question</VisibleText>
        </legend>
        <div
          class="dataset-benchmarks-selector__radio-wrapper"
          v-for="question in filteredQuestions"
          :key="`select-${question._id.$oid}`"
        >
          <label
            class="form-checkbox-label dataset-benchmarks-selector__radio-label"
            :for="`gq-${question._id.$oid}`"
          >
            <input
              class="form-checkbox-input"
              type="checkbox"
              :disabled="
                question.approved_data_type !==
                  clientQuestion.approved_data_type
              "
              :id="`gq-${question._id.$oid}`"
              :name="question._id.$oid"
              :value="question._id.$oid"
              v-model="selectedQuestions"
            />
            <span class="dataset-table__match-title">
              {{ question.question_title }}
            </span>
            <span class="dataset-table__match-text">
              {{ question.question_text }}
            </span>
            <span class="dataset-question-select__values-txt">
              Values: {{ getValuesText(question) }}
            </span>
          </label>
        </div>
      </fieldset>
      <button
        type="submit"
        class="side-pannel__primary-btn"
        :disabled="!$pigeonline.permissions.hasPermission('update-dataset')"
        @click="copyDetails"
      >
        <VisibleText>Copy Details</VisibleText>
      </button>
      <button
        type="button"
        class="side-pannel__close-btn"
        @click="closeCopyDetails"
      >
        <VisibleText>Close</VisibleText>
      </button>

      <span
        :class="[
          'dataset-table__values-msg',
          { 'success-msg__inline': message.success }
        ]"
      >
        {{ message.success }}
      </span>
      <span
        :class="[
          'dataset-table__values-msg',
          { 'error-msg__inline': message.err }
        ]"
      >
        {{ message.err }}
      </span>
    </form>
  </SidePannel>
</template>

<script>
// Components
import SidePannel from "@/components/UI/SidePannel.vue"
import Spinner from "@/components/UI/Spinner.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Services
import DatasetsService from "@/services/datasetsService.js"

export default {
  name: "DatasetTableOptionsCopyDetailsPannel",
  components: {
    SidePannel,
    Spinner,
    SvgIconDecorative
  },
  props: {
    clientQuestionId: {
      default: () => "",
      type: String,
      required: true
    }
  },
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      methodName: null,
      message: {
        err: "",
        success: ""
      },
      valueToLabel: new Map(),
      isLoading: false,
      questionSearch: null,
      selectedQuestions: [],
      resetListener: false
    }
  },
  computed: {
    clientQuestion() {
      return (
        this.$store.getters["datasetDetails/getClientQuestions"].find(
          question => question._id.$oid === this.clientQuestionId
        ) || {}
      )
    },
    filteredQuestions() {
      const keys = ["question_title", "question_text"]
      return this.$options.filters.searchInObjectValues(
        this.$store.getters["datasetDetails/getClientQuestions"],
        keys,
        this.questionSearch
      )
    },
    isCopyingDetails() {
      return this.$store.getters["datasetWizard/getCopyingDetails"]
        .isCopyingDetails
    }
  },
  methods: {
    closeCopyDetails() {
      this.resetMessage()
      this.$store.dispatch("datasetWizard/setCopyingDetails", {
        isCopyingDetails: false,
        methodName: "copyLabels" //default value
      })
    },
    getValuesText(clientQuestion) {
      const labels = clientQuestion.unique_values.reduce((labels, value) => {
        if (value.label) labels.push(`${value.value} - ${value.label}`)
        else labels.push(`${value.value}`)
        return labels
      }, [])
      return labels.length > 0 ? labels.join(", ") : ""
    },
    resetMessage() {
      this.message = {
        err: "",
        success: ""
      }
    },
    async copyDetails() {
      this.resetMessage()
      await this[
        this.$store.getters["datasetWizard/getCopyingDetails"].methodName
      ]()

      this.selectedQuestions = []
    },
    async copyLabels() {
      this.isLoading = true
      await Promise.all(
        this.selectedQuestions.map(async questionId => {
          const clientQuestion = this.$store.getters[
            "datasetDetails/getClientQuestions"
          ].find(question => question._id.$oid === questionId)
          const copiedLabels = clientQuestion.unique_values.reduce(
            (copiedLabels, value) => {
              if (this.valueToLabel.has(value.value)) {
                value.label = this.valueToLabel.get(value.value)
                copiedLabels.push(value)
              } else copiedLabels.push(value)
              return copiedLabels
            },
            []
          )
          const uniqueValues = { unique_values: copiedLabels }
          await this.DATASETS_SERVICE.updateClientQuestion(
            clientQuestion._id.$oid,
            uniqueValues
          ).catch(e =>
            console.error(
              `DatasetTableOptionsCopyDetailsPannel:copyLabels: ${e}`
            )
          )
        })
      )
        .then(() => (this.message.success = "Success: Labels copied."))
        .catch(e => {
          console.error(`DatasetTableOptionsCopyDetailsPannel:copyLabels: ${e}`)
          this.message.err =
            "Error: There was a problem saving the details. Please try again."
        })
      this.$emit("update-questions")
      this.isLoading = false
    }
  },
  watch: {
    clientQuestion: {
      immediate: true,
      handler(clientQuestion) {
        if (Object.keys(clientQuestion).length > 0) {
          clientQuestion.unique_values.forEach(value => {
            this.valueToLabel.set(value.value, value.label)
          })
          this.resetListener = !this.resetListener
        }
      }
    }
  }
}
</script>
